import React, {useState} from 'react'
import {
    SidebarPusher,
    SidebarPushable,
    MenuItem,
    Menu,
    Sidebar, Modal, Table, Button,
} from 'semantic-ui-react'
import {useUserSelector} from "../../../../store/selectors/authorization.selectors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faSignOut, faTimesCircle, faUsers, faHeadset} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import {Actions} from "../../../../store/actions/authorization.actions";
import AuthorizationThunk from "../../../../store/thunk/authorization.thunk";
import {useProjectMemberListSelector, useProjectMemberSelector} from "../../../../store/selectors/team.selectors";
import FirebaseUsage from "../../../../firebase/firebase.usage";
import {MemberOrganizationEnum} from "../../../../models/member-organization.enum";
import ProjectMembersModel from "../../../../models/responses/project-members.model";
import RecentInput from "../SearchBar/components/RecentUserInput";
import UserTickets from "./components/UserSupportTickets";

const SidebarExampleSidebar = (props) => {
    const { visible, setVisible } = props;
    const user = useUserSelector();
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const projectMembersList = useProjectMemberListSelector();
    const projectMember = useProjectMemberSelector();
    const [recentMember, setRecentMember] = useState<ProjectMembersModel | null>(null);
    const [showTickets, setShowTickets] = useState(false);

    return (<SidebarPushable>
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    icon='labeled'
                    // inverted
                    onHide={() => setVisible(false)}
                    vertical
                    visible={visible}
                    width={"thin"}
                    direction={"right"}
                >
                    {user && user.photoURL ?
                        <img src={user?.photoURL} alt="user"
                             style={{width: "60px", height: "60px", borderRadius: "50%", margin: "20px auto"}}/>
                        : <div className="user-image-div-sidebar"
                               style={{backgroundColor: user?.userColour ? user?.userColour : '#0d71bb',
                                   width: "60px", height: "60px", borderRadius: "50%", margin: "20px auto"
                               }}>{user && user.displayName ? user.displayName[0].toUpperCase() :
                            user?.userEmail[0].toUpperCase()}</div>}
                        <h5 className={"user-name-sidebar"}
                        style={{marginTop: "-10px"}}
                >{user?.displayName ? user.displayName : user?.userEmail}</h5>
                    <MenuItem as='a' onClick={() => {
                        dispatch(Actions.setUserSidebarOpen(false))
                        // @ts-ignore
                        dispatch(AuthorizationThunk.logOut())
                    }}>
                        <FontAwesomeIcon icon={faSignOut} />
                        <br/>
                        Sign out
                    </MenuItem>
                    {projectMember?.grade === MemberOrganizationEnum.SUPER_ACTOR &&
                        <MenuItem as='a'
                                  onClick={() => setModalOpen(true)}
                        >
                            <FontAwesomeIcon icon={faUsers} />
                            <br/>
                            Manage users (admin only)
                        </MenuItem>}
                    <MenuItem as='a'
                              onClick={() => setShowTickets(true)}>
                        <FontAwesomeIcon icon={faHeadset} />
                        <br/>
                        My support requests
                    </MenuItem>
                    {/*<MenuItem as='a'>*/}
                    {/*    <Icon name='gamepad' />*/}
                    {/*    Games*/}
                    {/*</MenuItem>*/}
                    {/*<MenuItem as='a'>*/}
                    {/*    <Icon name='camera' />*/}
                    {/*    Channels*/}
                    {/*</MenuItem>*/}
                </Sidebar>
                <SidebarPusher style={{minWidth: "100%"}}>
                    {props.children}
                </SidebarPusher>
            {modalOpen && <Modal open={modalOpen} closeIcon onClose={() => setModalOpen(false)}>
                <Modal.Header>Manage users</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <div style={{maxHeight: "70vh", overflow: "auto"}}>
                            <Table celled>
                                <Table.Header>
                                    <Table.Row style={{position: "sticky", top: 0}}>
                                        <Table.HeaderCell width={1}>Active</Table.HeaderCell>
                                        <Table.HeaderCell width={4}>Member</Table.HeaderCell>
                                        <Table.HeaderCell width={4}>Last seen</Table.HeaderCell>
                                        <Table.HeaderCell>Actions</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {projectMembersList && projectMembersList.filter(member =>
                                        member.grade !== "Groups")
                                        .sort((b, a) =>
                                        {
                                            if (a.lastViewed && b.lastViewed) {
                                                return a.lastViewed.seconds - b.lastViewed.seconds
                                            }
                                            if (!a.lastViewed && b.lastViewed) {
                                                return -1
                                            }
                                            if (a.lastViewed && !b.lastViewed) {
                                                return 1
                                            }
                                            return 0
                                        })
                                        .map((member) => {
                                        return <Table.Row key={`manage-modal-${member.memberId}`}>
                                            <Table.Cell textAlign={"center"}>{
                                                member.live ? <FontAwesomeIcon icon={faCheckCircle} color={"green"} size={"lg"}/> :
                                                    <FontAwesomeIcon icon={faTimesCircle} color={"red"} size={"lg"}/>
                                            }</Table.Cell>
                                            <Table.Cell>{member.userEmail}</Table.Cell>
                                            <Table.Cell>{member.lastViewed ? new Date(member.lastViewed.seconds * 1000).toLocaleString() : "Never"}</Table.Cell>
                                            <Table.Cell>
                                                <Button disabled>
                                                    Message
                                                </Button>
                                                <Button disabled>
                                                    Sign Out
                                                </Button>
                                                <Button onClick={() => setRecentMember(member)}>
                                                    View recent
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    }
                                    )}
                                </Table.Body>
                            </Table>
                        </div>
                    </Modal.Description>
                </Modal.Content>
            </Modal>}
            {recentMember && <RecentInput member={recentMember} setOpen={setRecentMember}/>}
            {showTickets && <UserTickets setOpen={setShowTickets} />}
            </SidebarPushable>
    )
}

export default SidebarExampleSidebar