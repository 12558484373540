import * as React from "react";
import { useCallback, useMemo } from "react";
import Calendar, { CalendarTileProperties } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styles from "../HeaderCalendar.module.scss";
import moment from "moment";
import uuid from "uuid";
import {
  datesArrayFromDuration,
  max,
  min,
} from "../../../../../../../utils/calculations.utils";
import { getPredStatusClass } from "../calendar-utils/calendar.utils";
import TaskModel from "../../../../../../../models/responses/task.model";
import FirebaseUsage from "../../../../../../../firebase/firebase.usage";
import TaskStatusModel from "../../../../../../../models/responses/task-status.model";
import { useTaskCalendarSelector } from "../../../../../../../store/selectors/calendar.selectors";
import { isNonWorkingDay } from "../../../../../../../utils/date.utils";

// const PinLabel: React.FC<{ text: string, pinned: boolean }> = ({text, pinned}) => (
//     <Popup
//         content={text}
//         trigger={
//             pinned
//                 ? <div className={styles.PinTrigger}>
//                     <FontAwesomeIcon className={styles.PinnedDateIcon} icon={faThumbtack}/>
//                 </div>
//                 : <div className={styles.PinTrigger}/>
//         }
//     />
// );

interface InjectedProps {
  activeTask: TaskModel;
  schedule: Boolean;
}

export const CustomCalendar: React.FC<InjectedProps> = React.memo(
  ({ activeTask, schedule }) => {
    const dateFormat = useMemo(() => "YYYY:MM:DD", []);
    const calendar = useTaskCalendarSelector();
    const minDate = useMemo(() => {
      const arr: any[] = [
        activeTask.early_start_date ? activeTask.early_start_date.seconds : null,
        activeTask.early_end_date ? activeTask.early_end_date.seconds : null,
        activeTask.late_start_date ? activeTask.late_start_date.seconds : null,
        activeTask.late_end_date ? activeTask.late_end_date.seconds : null,
        activeTask.act_start_date ? activeTask.act_start_date.seconds : null,
        activeTask.act_end_date ? activeTask.act_end_date.seconds : null,
        activeTask.blActualStartDate ? activeTask.blActualStartDate.seconds : null,
        schedule ? activeTask.blLateEndDate ? activeTask.blLateEndDate.seconds : null : activeTask.blEarlyStartDate ? activeTask.blEarlyStartDate.seconds : null,
      ];
      return new Date(min(arr) * 1000);
    }, [
      activeTask.early_start_date,
      activeTask.early_end_date,
      activeTask.late_start_date,
      activeTask.late_end_date,
      activeTask.act_end_date,
      activeTask.act_start_date,
        activeTask.blActualStartDate,
      activeTask.blEarlyStartDate,
        activeTask.blLateEndDate,
        schedule,
    ]);
    const maxDate = useMemo(() => {
      const arr: any[] = [
        activeTask.early_start_date
          ? activeTask.early_start_date.seconds
          : null,
        activeTask.early_end_date ? activeTask.early_end_date.seconds : null,
        activeTask.late_start_date ? activeTask.late_start_date.seconds : null,
        activeTask.late_end_date ? activeTask.late_end_date.seconds : null,
        activeTask.act_start_date ? activeTask.act_start_date.seconds : null,
        activeTask.act_end_date ? activeTask.act_end_date.seconds : null,
        activeTask.blActEndDate ? activeTask.blActEndDate.seconds : null,
        schedule ? activeTask.blLateEndDate ? activeTask.blLateEndDate.seconds : null : activeTask.blEarlyEndDate ? activeTask.blEarlyEndDate.seconds : null,
        FirebaseUsage.timestamp().seconds,
      ];
      return new Date(max(arr) * 1000);
    }, [
      activeTask.early_start_date,
      activeTask.early_end_date,
      activeTask.late_start_date,
      activeTask.late_end_date,
      activeTask.act_end_date,
      activeTask.act_start_date,
      activeTask.blLateEndDate,
        activeTask.blEarlyEndDate,
        activeTask.blActEndDate,
            schedule,
    ]);

    const dateArr = useMemo(() => {
      let dates = datesArrayFromDuration(minDate, maxDate);
      return dates;
    }, [minDate, maxDate]);

    const predStatusClass: string = useMemo(
      () => getPredStatusClass(activeTask),
      [activeTask]
    );

    const calculateTileClassname = useCallback(
      (tileProps: CalendarTileProperties) => {
        const curTileDate = tileProps.date;
        let mixedClass = "";
        let forecastStart: Date;
        let forecastEnd: Date;
        let today = new Date().setHours(0, 0, 0, 0);

        if (isNonWorkingDay(curTileDate, calendar)) {
          mixedClass += ` ${styles.NonWorkingDay}`;
          return mixedClass;
        }

        if (
          activeTask.status === TaskStatusModel.IN_PROGRESS ||
          activeTask.status === TaskStatusModel.SUSPENDED
        ) {
          if (
            activeTask.act_start_date &&
            activeTask.late_end_date &&
            moment(new Date(activeTask.act_start_date.seconds * 1000)).format(
              dateFormat
            ) <= moment(curTileDate).format(dateFormat) &&
            moment(new Date(activeTask.late_end_date.seconds * 1000)).format(
              dateFormat
            ) >= moment(curTileDate).format(dateFormat)
          ) {
            mixedClass += ` ${styles.IncludedSchedule}`;
          }
        } else if (
          activeTask.status === TaskStatusModel.NOT_STARTED ||
          activeTask.status === TaskStatusModel.BLOCK
        ) {
          if (
            activeTask.early_start_date &&
            activeTask.late_end_date &&
            moment(new Date(activeTask.early_start_date.seconds * 1000)).format(
              dateFormat
            ) <= moment(curTileDate).format(dateFormat) &&
            moment(new Date(activeTask.late_end_date.seconds * 1000)).format(
              dateFormat
            ) >= moment(curTileDate).format(dateFormat)
          ) {
            mixedClass += ` ${styles.IncludedSchedule}`;
          }
        }
        forecastStart = schedule ? activeTask.act_start_date ?
            activeTask.act_start_date.toDate() :
            activeTask.late_start_date.toDate() :
            activeTask.act_start_date ? activeTask.act_start_date.toDate() :
            activeTask.early_start_date.toDate();
        forecastEnd = schedule ? activeTask.act_end_date ?
            activeTask.act_end_date.toDate() :
            activeTask.declaredCompleteTimestamp ?
                activeTask.declaredCompleteTimestamp.toDate() :
                activeTask.late_end_date.toDate() :
            activeTask.act_end_date ? activeTask.act_end_date.toDate() :
                activeTask.declaredCompleteTimestamp ?
                    activeTask.declaredCompleteTimestamp.toDate() :
            activeTask.early_end_date.toDate();

          if (
            moment(curTileDate).format(dateFormat) >=
              moment(forecastStart).format(dateFormat) &&
            moment(curTileDate).format(dateFormat) <=
              moment(forecastEnd).format(dateFormat) &&
            !isNonWorkingDay(curTileDate, calendar) &&
            activeTask.status !== TaskStatusModel.DECLARED_COMPLETE &&
            activeTask.status !== TaskStatusModel.COMPLETE
          ) {
            mixedClass += ` ${predStatusClass}`;
          }
          let periodStart = forecastStart ? (Math.floor((forecastStart.getTime() / 1000) / 86400) * 86400) * 1000 + (forecastStart.getTimezoneOffset() * 60 * 1000): new Date().getTime();
          if (
              periodStart <=
              curTileDate.getTime() &&
              forecastEnd.getTime() >=
             curTileDate.getTime() &&
              curTileDate.getTime() >=
              today
          ) {
              mixedClass += schedule ? ` ${styles.RedClass}` : ` ${styles.GreenClass}`;
          }
        if (activeTask.act_start_date) {
          const actStart = activeTask.act_start_date;
          if (activeTask.act_end_date || activeTask.declaredCompleteTimestamp) {
            const actEnd = activeTask.act_end_date
              ? activeTask.act_end_date
              : activeTask.declaredCompleteTimestamp
              ? activeTask.declaredCompleteTimestamp
              : FirebaseUsage.timestamp();
            if (
              moment(curTileDate).format(dateFormat) >=
                moment(new Date(actStart.seconds * 1000)).format(dateFormat) &&
              moment(curTileDate).format(dateFormat) <=
                moment(new Date(actEnd.seconds * 1000)).format(dateFormat) &&
              !isNonWorkingDay(curTileDate, calendar)
            ) {
              mixedClass += ` ${styles.BlueStatus}`;
            }
          } else {
            if (
              moment(curTileDate).format(dateFormat) >=
                moment(new Date(actStart.seconds * 1000)).format(dateFormat) &&
              moment(curTileDate).format(dateFormat) <=
                moment(new Date()).format(dateFormat) &&
              !isNonWorkingDay(curTileDate, calendar)
            ) {
              mixedClass += ` ${styles.BlueStatus}`;

              // if (
              //     moment(curTileDate).format(dateFormat) === moment(new Date()).format(dateFormat) &&
              //     moment(activeTask.act_start_date.toDate()).format(dateFormat) !== moment(new Date()).format(dateFormat)
              // ) {
              //     mixedClass += ` ${styles.YellowClass}`;
              // }
            }
          }
        } else if (activeTask.act_end_date) {
          const actEnd = activeTask.act_end_date;
          if (
            moment(curTileDate).format(dateFormat) <=
              moment(new Date(actEnd.seconds * 1000)).format(dateFormat) &&
            !isNonWorkingDay(curTileDate, calendar)
          ) {
            mixedClass += ` ${styles.BlueStatus}`;
          }
        }

        if (
          !isNonWorkingDay(tileProps.date, calendar) &&
          moment(tileProps.date).format(dateFormat) >=
            moment(new Date()).format(dateFormat)
        ) {
          mixedClass += ` ${styles.Hover}`;
        }

        const baselineStart = activeTask.blActualStartDate ? activeTask.blActualStartDate.toDate() :
            schedule ? activeTask.blLateStartDate?.toDate() || null :
                activeTask.blEarlyStartDate?.toDate() || null;

        const baselineEnd = activeTask.blActEndDate ? activeTask.blActEndDate.toDate() :
            schedule ? activeTask.blLateEndDate?.toDate() || null :
                activeTask.blEarlyEndDate?.toDate() || null;

        if (baselineStart && baselineEnd) {
            if (
                moment(curTileDate).format(dateFormat) >=
                moment(baselineStart).format(dateFormat) &&
                moment(curTileDate).format(dateFormat) <=
                moment(baselineEnd).format(dateFormat) &&
                !isNonWorkingDay(curTileDate, calendar)
            ) {
                mixedClass += ` ${styles.BaselineClass}`;
            }
        }

        return mixedClass;
      },
      [activeTask, predStatusClass, dateFormat, calendar, schedule]
    );

    const getDisabledTilesHandler = useCallback(
      (tileProps: CalendarTileProperties) => {
        const className = calculateTileClassname(tileProps);
        return (
          (isNonWorkingDay(tileProps.date, calendar) ||
            moment(tileProps.date).format(dateFormat) <
              moment(new Date()).format(dateFormat)) &&
          !className.includes(styles.BlueStatus) &&
          !className.includes(predStatusClass) && !className.includes(styles.BaselineClass)
        );
      },
      [calendar, dateFormat, calculateTileClassname, predStatusClass, schedule]
    );

    return (
      <React.Fragment>
        {dateArr.map((date) => (
          <div key={uuid.v4()} className={styles.CalendarWrapper}>
            <h2 className={styles.CalendarHeader}>
              {moment(date).format("MMMM YYYY")}
            </h2>
            <Calendar
              className={styles.CustomizedCalendar}
              value={date}
              formatShortWeekday={(_, date) => date.toDateString().slice(0, 2)}
              // minDate={getDateByMonthNumber(date)}
              showNavigation={false}
              tileClassName={calculateTileClassname}
              // onClickDay={onPinHandler}
              // tileContent={(props) => getTileContentHandler(props)}
              tileDisabled={getDisabledTilesHandler}
            />
          </div>
        ))}
      </React.Fragment>
    );
  }
  //   (p1, p2) => {
  //     return (
  //       p1.activeTask.task_id === p2.activeTask.task_id &&
  //       p1.activeTask.status === p2.activeTask.status &&
  //       p1.schedule === p2.schedule
  //     );
  //   }
);
