import React, {useCallback, useEffect, useState} from "react";
import {useTypedSelector} from "../../../../../store/selectors/selectors.utils";
import {TaskPredecessors} from "./TaskPredecessors";
import {TaskSuccessors} from "./TaskSuccessors";
import {TaskListSection} from "./TaskListSection/TaskListSection";
import {TaskListSectionModel} from "../../../../../models/task-list-section.model";
import {TaskListHeadlineModel} from "../../../../../models/task-list-headline.model";
import InfiniteScroll from "react-infinite-scroller";
import {useDispatch} from "react-redux";
import * as taskActions from "../../../../../store/actions/task.actions";
import {Loader} from "semantic-ui-react";
import {useActiveProjectSelector, useProjectViewSelector} from "../../../../../store/selectors/project.selectors";
import {TaskType} from "../../../../../models/task-type";
import {useSearchParamatersSelector} from "../../../../../store/selectors/search.selectors";
import moment from "moment";
import TaskModel from "../../../../../models/responses/task.model";
import GoogleMapVisGL from "../../../components/GoogleMap/GoogleMapVisGL";
import filterTaskList from "../../../../../utils/task-filtering";
import TaskStatusModel from "../../../../../models/responses/task-status.model";

export const TaskList = () => {
  const [view, setView] = useState<"future" | "past">("future");
  const [sortCriteria, setSortCriteria] = useState<"index" | "deadline" | "target">("index");
  const activeProject = useActiveProjectSelector();
  const dispatch = useDispatch();
  const projectView = useProjectViewSelector();

  let tasksNotMilestonesCount = 0;
  let queuedTasksCount = 0;
  let now = new Date();
  now.setMinutes(0, 0, 0);
  const today = moment(new Date())
    .minutes(0)
    .seconds(0)
    .milliseconds(0)
    .toDate();
  if (activeProject) {
    tasksNotMilestonesCount = activeProject?.tasksNotMilestonesCount;
  }

  let confirmedTaskList = useTypedSelector(
    (state) => state.task.tasks.confirmedComplete
  );

  let pendingTaskList = useTypedSelector(
      (state) => state.task.tasks.pending
  );

  const confirmedTasksCount = confirmedTaskList.length;
  const confirmedPercentage =
    ((confirmedTasksCount / tasksNotMilestonesCount) * 100) % 1 !== 0
      ? ((confirmedTasksCount / tasksNotMilestonesCount) * 100).toFixed(2)
      : (confirmedTasksCount / tasksNotMilestonesCount) * 100;
  let declaredCompleteList = useTypedSelector(
    (state) => state.task.tasks.declaredComplete
  );

  const declaredTasksCount = declaredCompleteList.length;
  const declaredPercentage =
    ((declaredTasksCount / tasksNotMilestonesCount) * 100) % 1 !== 0
      ? ((declaredTasksCount / tasksNotMilestonesCount) * 100).toFixed(2)
      : (declaredTasksCount / tasksNotMilestonesCount) * 100;

  let inProgressList = useTypedSelector((state) => state.task.tasks.inProgress);

  const inProgressTasksCount = inProgressList.length;
  const inProgressPercentage =
    ((inProgressTasksCount / tasksNotMilestonesCount) * 100) % 1 !== 0
      ? ((inProgressTasksCount / tasksNotMilestonesCount) * 100).toFixed(2)
      : (inProgressTasksCount / tasksNotMilestonesCount) * 100;
  if (activeProject) {
    queuedTasksCount =
      tasksNotMilestonesCount -
      confirmedTaskList.length -
      declaredCompleteList.length -
      inProgressList.length;
  }
  let queuedList = useTypedSelector((state) => state.task.tasks.queued);

  const queuedPercentage =
    (100 - +confirmedPercentage - +declaredPercentage - +inProgressPercentage) %
      1 !==
    0
      ? (
          100 -
          +confirmedPercentage -
          +declaredPercentage -
          +inProgressPercentage
        ).toFixed(2)
      : 100 -
        +confirmedPercentage -
        +declaredPercentage -
        +inProgressPercentage;
  const loading = useTypedSelector((state) => state.task.taskListLoading);
  const moreTasks = useTypedSelector((state) => state.task.moreTasks);
  const searchParams = useSearchParamatersSelector();
  const allTasks = [
    ...confirmedTaskList,
    ...declaredCompleteList,
    ...inProgressList,
    ...queuedList,
    ...pendingTaskList
  ];

  const [filteredConfirmedTaskList, setFilteredConfirmedTaskList] = useState<TaskModel[]>([]);
  const [filteredDeclaredCompleteList, setFilteredDeclaredCompleteList] = useState<TaskModel[]>([]);
  const [filteredInProgressList, setFilteredInProgressList] = useState<TaskModel[]>([]);
  const [filteredQueuedList, setFilteredQueuedList] = useState<TaskModel[]>([]);
  const [filteredPendingTaskList, setFilteredPendingTaskList] = useState<TaskModel[]>([]);
  const [inProgressOpen, setInProgressOpen] = useState(true);
  const [queuedOpen, setQueuedOpen] = useState(true);
  const [declaredCompleteOpen, setDeclaredCompleteOpen] = useState(false);
  const [confirmedCompleteOpen, setConfirmedCompleteOpen] = useState(false);
  const [pendingOpen, setPendingOpen] = useState(true);

  const navigateToTask = (task: TaskModel) => {
    let taskIndex = 1;
    let taskListType: any
    let page: number = 1;
    if (task.status === TaskStatusModel.COMPLETE) {
      taskListType = TaskListSectionModel.CONFIRMED_COMPLETE;
      confirmedTaskList.forEach((t, index) => {
        if (t.task_id === task.task_id) {
          taskIndex = index;
        }
      });
      page = Math.ceil(taskIndex / 10);
    } else if (task.status === TaskStatusModel.DECLARED_COMPLETE) {
      taskListType = TaskListSectionModel.CONFIRMED_COMPLETE;
        declaredCompleteList.forEach((t, index) => {
            if (t.task_id === task.task_id) {
            taskIndex = index;
            }
        });
      page = Math.ceil(taskIndex / 10);
    } else if (task.status === TaskStatusModel.IN_PROGRESS || task.flow) {
      taskListType = TaskListSectionModel.CONFIRMED_COMPLETE;
        inProgressList.forEach((t, index) => {
            if (t.task_id === task.task_id) {
            taskIndex = index;
            }
        });
      page = Math.ceil(taskIndex / 10);
    } else {
        queuedList.forEach((t, index) => {
          taskListType = TaskListSectionModel.CONFIRMED_COMPLETE;
            if (t.task_id === task.task_id) {
            taskIndex = index;
            }
        });
      page = Math.ceil(taskIndex / 10);
    }

  }

  useEffect(() => {
    let confirmedTaskList: TaskModel[] = []
    let declaredCompleteList: TaskModel[] = []
    let inProgressList: TaskModel[] = []
    let queuedList: TaskModel[] = []
    let pendingTaskList: TaskModel[] = []

    filterTaskList(allTasks, searchParams).forEach((task: TaskModel) => {
        if (task.status === TaskStatusModel.COMPLETE && (task.task_type === TaskType.TT_TASK || task.task_type === TaskType.TT_RSRC)) {
            confirmedTaskList.push(task)
        } else if (task.status === TaskStatusModel.DECLARED_COMPLETE && (task.task_type === TaskType.TT_TASK || task.task_type === TaskType.TT_RSRC)) {
            declaredCompleteList.push(task)
        } else if ((task.status === TaskStatusModel.IN_PROGRESS || task.status === TaskStatusModel.SUSPENDED || task.flow) && (task.task_type === TaskType.TT_TASK || task.task_type === TaskType.TT_RSRC)) {
            inProgressList.push(task)
        } else if (task.taskListType === TaskListSectionModel.PENDING && (task.task_type === TaskType.TT_TASK || task.task_type === TaskType.TT_RSRC)) {
          pendingTaskList.push(task)
        } else if (task.task_type === TaskType.TT_TASK || task.task_type === TaskType.TT_RSRC) {
            queuedList.push(task)
        }
    })
    if (sortCriteria === "index") {
        inProgressList = inProgressList.sort((a, b) => a.index! - b.index!);
        queuedList = queuedList.sort((a, b) => a.index! - b.index!);
    }
    if (sortCriteria === "target") {
       inProgressList = inProgressList.sort((a, b) => {
         if (a.expiryDate) {
           if (b.expiryDate) {
             return a.expiryDate.seconds - b.expiryDate.seconds;
           }
           return -1;
         }
         if (b.expiryDate) {
           return 1;
         }
         return a.early_end_date.seconds - b.early_end_date.seconds;
       });
      queuedList = inProgressList.sort((a, b) => a.early_end_date.seconds - b.early_end_date.seconds);
    }
    if (sortCriteria === "deadline") {
      inProgressList = inProgressList.sort((a, b) => a.late_end_date.seconds - b.late_end_date.seconds);
      queuedList = queuedList.sort((a, b) => a.late_end_date.seconds - b.late_end_date.seconds);
    }
    setFilteredConfirmedTaskList(confirmedTaskList);
    setFilteredDeclaredCompleteList(declaredCompleteList);
    setFilteredInProgressList(inProgressList);
    setFilteredQueuedList(queuedList);
    setFilteredPendingTaskList(pendingTaskList);
  }, [searchParams, sortCriteria, confirmedTaskList, declaredCompleteList, inProgressList, queuedList, pendingTaskList]);

  const openPredecessors = useTypedSelector(
    (state) => state.task.predecessors.open
  );
  const openSuccessors = useTypedSelector(
    (state) => state.task.successors.open
  );
  const loadMoreHandler = useCallback(
    (page: number) => {
      if (view === "future") {
        if (!loading.QueuedIsLoading && moreTasks.queued) {
          dispatch(
            taskActions.Actions.loadMoreTaskList(
              page,
              TaskListSectionModel.QUEUED
            )
          );
        }
      } else {
        if (
          !loading.ConfirmedCompleteIsLoading &&
          moreTasks.confirmedComplete
        ) {
          dispatch(
            taskActions.Actions.loadMoreTaskList(
              page,
              TaskListSectionModel.CONFIRMED_COMPLETE
            )
          );
        }
      }
    },
    [
      dispatch,
      loading.ConfirmedCompleteIsLoading,
      loading.QueuedIsLoading,
      moreTasks.confirmedComplete,
      moreTasks.queued,
      view,
    ]
  );

  if (openPredecessors) {
    return <TaskPredecessors />;
  }

  if (openSuccessors) {
    return <TaskSuccessors />;
  }

  return (
      <div style={{minWidth: "100%"}}>
        <div className="tasks-sort-header"
             style={{
               // position: "sticky",
               // top: "0",
               backgroundColor: "rgb(255 255 255 / 80%)",
               zIndex: "200"
             }}>
          <div className="priority-task">
            <div
                className={"priority " + (sortCriteria === "index" ? "active" : "")}
                onClick={() => setSortCriteria("index")}
            >
              <span className="priority-text">Priority</span>&nbsp;
            </div>
            <div className="task-desc">Deliverable</div>
          </div>
          <div className="right-filters">
            <div
                className={"time " + (sortCriteria === "target" ? "active" : "")}
                onClick={() => setSortCriteria("target")}
            >
              <div className="time-text">Target</div>
              &nbsp;
            </div>
            <div className={"critical " + (sortCriteria === "deadline" ? "active" : "")}
                 onClick={() => setSortCriteria("deadline")}
            >Deadline
            </div>
          </div>
        </div>
        <div className="lazy-load-container">
          {projectView === "task-map" && <GoogleMapVisGL/>}
          <InfiniteScroll
              pageStart={1}
              loadMore={loadMoreHandler}
              initialLoad={false}
              hasMore={
                view === "future"
                    ? !loading.QueuedIsLoading && moreTasks.queued
                    : !loading.ConfirmedCompleteIsLoading && moreTasks.confirmedComplete
              }
              useWindow={false}
          >
            <div className="lazy-load-content" style={{zIndex: "100"}}>
              {pendingTaskList.length > 0 ? (
                  <TaskListSection
                      text="New tasks pending approval"
                      moreTasks={moreTasks.pending}
                      isLoading={loading.PendingIsLoading}
                      headline={TaskListHeadlineModel.PENDING}
                      title={
                          TaskListHeadlineModel.PENDING +
                          confirmedTasksCount +
                          (confirmedTasksCount === 1 ? " task" : " tasks")
                      }
                      taskList={filteredPendingTaskList}
                      view={view}
                      setView={setView}
                      total={allTasks.length}
                      quality={false}
                      type={"task"}
                      open={pendingOpen}
                      setOpen={setPendingOpen}
                  />
              ) : null}
              {filteredConfirmedTaskList.length > 0 ? (
                  <TaskListSection
                      text="Confirmed Complete"
                      moreTasks={moreTasks.confirmedComplete}
                      isLoading={loading.ConfirmedCompleteIsLoading}
                      headline={TaskListHeadlineModel.CONFIRMED_COMPLETE}
                      title={
                          TaskListHeadlineModel.CONFIRMED_COMPLETE +
                          " - " +
                          confirmedPercentage +
                          "%  - " +
                          confirmedTasksCount +
                          (confirmedTasksCount === 1 ? " task" : " tasks")
                      }
                      taskList={filteredConfirmedTaskList}
                      view={view}
                      setView={setView}
                      total={allTasks.length}
                      quality={false}
                      type={"task"}
                      open={confirmedCompleteOpen}
                      setOpen={setConfirmedCompleteOpen}
                  />
              ) : null}
              {filteredDeclaredCompleteList.length > 0 ? (
                  <TaskListSection
                      text="Completed"
                      moreTasks={moreTasks.declaredComplete}
                      isLoading={loading.DeclaredCompleteIsLoading}
                      headline={TaskListHeadlineModel.DECLARED_COMPLETE}
                      title={
                          TaskListHeadlineModel.DECLARED_COMPLETE +
                          " - " +
                          declaredPercentage +
                          "%  - " +
                          declaredTasksCount +
                          (declaredTasksCount === 1 ? " task" : " tasks")
                      }
                      taskList={filteredDeclaredCompleteList}
                      view={view}
                      setView={setView}
                      total={allTasks.length}
                      quality={false}
                      type={"task"}
                      open={declaredCompleteOpen}
                      setOpen={setDeclaredCompleteOpen}
                  />
              ) : null}
              {filteredInProgressList.length > 0 ? (
                  <TaskListSection
                      text="Countdown"
                      moreTasks={moreTasks.inProgress}
                      isLoading={loading.WorkInProgressIsLoading}
                      headline={TaskListHeadlineModel.WORK_IN_PROCESS}
                      title={
                          TaskListHeadlineModel.WORK_IN_PROCESS +
                          " - " +
                          inProgressPercentage +
                          "%  - " +
                          inProgressTasksCount +
                          (inProgressTasksCount === 1 ? " task" : " tasks")
                      }
                      taskList={filteredInProgressList}
                      view={view}
                      setView={setView}
                      total={allTasks.length}
                      quality={false}
                      type={"task"}
                      open={inProgressOpen}
                      setOpen={setInProgressOpen}
                  />
              ) : null}
              {filteredQueuedList.length > 0 ? (
                  <TaskListSection
                      text="Forecast Start"
                      moreTasks={moreTasks.queued}
                      isLoading={loading.QueuedIsLoading}
                      headline={TaskListHeadlineModel.QUEUED}
                      title={
                          TaskListHeadlineModel.QUEUED + " - " +
                          queuedPercentage +
                          "%  - " +
                          queuedTasksCount +
                          (queuedTasksCount === 1 ? " task" : " tasks")
                      }
                      taskList={filteredQueuedList}
                      view={view}
                      setView={setView}
                      total={allTasks.length}
                      quality={false}
                      type={"task"}
                      open={queuedOpen}
                      setOpen={setQueuedOpen}
                  />
              ) : null}
              {(view === "future"
                  ? loading.QueuedIsLoading
                  : loading.ConfirmedCompleteIsLoading) && (
                  <div className="load-more-items-wrap" key={0}>
                    <Loader active/>
                  </div>
              )}
            </div>
          </InfiniteScroll>
        </div>
      </div>
  );
};
