import ProjectModel from "../../models/responses/project.model";
import {useTypedSelector} from "./selectors.utils";
import {Maybe} from "@martin_hotell/rex-tils";
import { ProjectLoadingStage } from "../reducers/project";
import GeoModel from "../../models/responses/geo.model";

// Projects
export const useProjectListSelector = () => useTypedSelector<ProjectModel[]>(state => state.project.projectList);
export const useArchiveProjectListSelector = () => useTypedSelector<ProjectModel[]>(state => state.project.archiveProjectList);
export const useActiveProjectSelector = () => useTypedSelector<Maybe<ProjectModel>>(state => state.project.activeProject);
export const useIsLoadedProjectListSelector = () => useTypedSelector<boolean>(state => state.project.projectListLoaded);
export const useIsLoadedActiveProjectSelector = () => useTypedSelector<boolean>(state => state.project.activeProjectLoaded);
export const useUploadingNewProject = () => useTypedSelector<boolean>(state => state.project.uploadingNewProject);

export const useCpmCompleteSelector = () => useTypedSelector<boolean>(state => state.project.cpmComplete);

export const useCpmMapSelector = () => useTypedSelector<Map<string, any>>(state => state.project.cpmMap);

export const useActiveProjectCalendarsSelector = () => useTypedSelector<Map<string, any>>(state => state.project.activeProjectCalendars);

export const useTrackedMilestonesSelector = () => useTypedSelector<any[]>(state => state.project.trackedMilestones);

export const useMonteCarloIndexSelector = () => useTypedSelector<number>(state => state.project.monteCarloIndex);


export const useUploadingNewProjectProgressPercent = () => useTypedSelector<number>(state =>
Math.round((state.project.detectedSteps.uploadedWrites / state.project.detectedSteps.totalWrites) * 100)
);
export const useProjectLoadingStageSelector = () => useTypedSelector<ProjectLoadingStage>(state => state.project.projectLoadingStage);
export const useProjectCalculationsInProcessSelector = () => useTypedSelector<boolean>(state => state.project.calculationsInProcess);

export const useProjectViewSelector = () => useTypedSelector<string>(state => state.project.view);

export const useActiveProjectQualitySelector = () => useTypedSelector<any>(state => state.project.activeProjectQuality);

export const useGeoDataSelector = () => useTypedSelector<any>(state => state.project.geoData);

export const useActiveGeoSelector = () => useTypedSelector<any>(state => state.project.activeGeo);

export const useChatLogSelector = () => useTypedSelector<any[]>(state => state.project.chatLog);

export const useChatInitialisedSelector = () => useTypedSelector<boolean>(state => state.project.chatInitialised);

export const useVectorStoreSelector = () => useTypedSelector<any>(state => state.project.vectorStore);

export const useLedgerOpenSelector = () => useTypedSelector<boolean>(state => state.project.ledgerOpen);

export const useCalendarOpenSelector = () => useTypedSelector<boolean>(state => state.project.calendarOpen);

export const useTeamOpenSelector = () => useTypedSelector<boolean>(state => state.project.teamOpen);