import React, {useCallback, useEffect, useMemo, useState} from "react";
import styles from "./TaskContainer.module.scss";
import TaskModel from "../../../../../../models/responses/task.model";
import {TaskIcon} from "../TaskIcon/TaskIcon";
import {Dropdown, Loader} from "semantic-ui-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight, faSnooze,} from "@fortawesome/pro-light-svg-icons";
import {faHandshakeAngle} from "@fortawesome/free-solid-svg-icons";
import {useTypedSelector} from "../../../../../../store/selectors/selectors.utils";
import {TaskOptions} from "./TaskOptions";
import * as taskActions from "../../../../../../store/actions/task.actions";
import {useDispatch} from "react-redux";
import classNames from "classnames";
import {
    useActiveTaskSelector,
    usePredeccessorsViewSelector,
    useSuccessorsViewSelector,
} from "../../../../../../store/selectors/task/task.selectors";
import {Maybe} from "@martin_hotell/rex-tils";
import {useRelationshipsSelector} from "../../../../../../store/selectors/relationships/relations.selectors";
import TaskStatusModel from "../../../../../../models/responses/task-status.model";
import moment from "moment";
import {daysArrayFromDuration,} from "../../../../../../utils/calculations.utils";
import {TaskListSectionModel} from "../../../../../../models/task-list-section.model";
import {useActiveProjectSelector} from "../../../../../../store/selectors/project.selectors";
import {getSecsDuration, isNonWorkingPeriod,} from "../../../../../../utils/date.utils";
import {TaskType} from "../../../../../../models/task-type";
import {CalendarModel} from "../../../../../../models/responses/calendar.model";
import {Slider} from "react-semantic-ui-range";
import {TaskThunk} from "../../../../../../store/thunk/task.thunk";
import {TaskEventModal} from "../TaskEventModal";
import EventTextModel from "../../../../../../models/event-text-model.enum";
import {EvaluationOptions} from "../../../../../../models/evaluation-options.enum";

interface InjectedProps {
  task: TaskModel;
  goToPred?: () => void;
  goToSuc?: () => void;
  hidePassBtn?: boolean;
  type?: "pred" | "succ";
  showPredType?: boolean;
}

export const TaskContainer: React.FC<InjectedProps> = React.memo(
  ({ goToPred, goToSuc, ...props }) => {
    const [taskInfoClass, setTaskInfoClass] = useState<any>(null);
    const [spinningIcon, setSpinningIcon] = useState<boolean>(true);
    const { task } = props;
    const [sliderValue, setSliderValue] = useState<number>(
          task.progress ? task.progress.sort((a, b) => b.date.seconds - a.date.seconds)[0].progress : 0);
    const [progressChanged, setProgressChanged] = useState<boolean>(false);
    const [showEventModal, setShowEventModal] = useState<boolean>(false);
      const openPredecessors = useTypedSelector(
          (state) => state.task.predecessors.open
      );
      const openSuccessors = useTypedSelector(
          (state) => state.task.successors.open
      );

    const currentUser = useTypedSelector((state) => state.authorization.user);
    const dispatch = useDispatch();
    const activeTask = useActiveTaskSelector();
    const activeProject = useActiveProjectSelector();
    const criticalThreshold = 5

      const sliderSettings = {
          start: 0,
          min: 0,
          max: 100,
          step: 1,
          onChange: (value: number) => {
              setSliderValue(value);
              setProgressChanged(true);
          }
      };

    // const canMakeActive = useTypedSelector(state => !state.task.predecessors.open && !state.task.successors.open);
    const loading = useTypedSelector<boolean>(
      (state) => state.task.toolbarLoadingMap[task.task_id]
    );
    const relationships = useRelationshipsSelector(task.task_id);
    const predType = useMemo(
      () =>
        relationships.length
          ? relationships[0].pred_type.replace("PR_", "")
          : "",
      [relationships]
    );
    const [delayOnMouseEnter, setDelayOnMouseEnter] = useState(null);
    const [taskInfo, setTaskInfo] = useState<string>("");
    // const [underneathText, setUnderneathText] = useState<any>(null);
    const [criticalIn, setCriticalIn] = useState<any>("-");
    const [isCritical, setIsCritical] = useState<any>(false);
    const [isCriticalPath, setIsCriticalPath] = useState<any>(false);
    const [isNearCritical, setIsNearCritical] = useState<any>(false);
    const [taskOptionsOpen, setTaskOptionsOpen] = useState<boolean>(false);
    const [editReason, setEditReason] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<EventTextModel>(EventTextModel.COMPLETE_TASK);
      const [suspendReason, setSuspendReason] = useState<
          any
      >("No reason");
      const [evaluationResult, setEvaluationResult] = useState<
          EvaluationOptions | "No result"
      >("No result");
      const [defDate, setDefDate] = useState<string>("");

      // const [completeEmail, setCompleteEmail] = useState<any>(null);
    const predecessorsView = usePredeccessorsViewSelector();
    const successorsView = useSuccessorsViewSelector();
    const today = moment(new Date())
        .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .toDate();

    const tomorrow = moment(new Date())
        .add(1, "days")
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .toDate();

    function formatTimeDifference(seconds: number, workingHoursPerDay: number, deadline: boolean) {
        if (deadline && seconds <= 0) {
            return "Now"
        }
        let hours: string | number, minutes: string | number, secondsLeft: string | number, secs: string | number;
        if (Math.abs(seconds / 3600) >= workingHoursPerDay) {
            return `${Math.floor((seconds / 3600) / workingHoursPerDay)} day${Math.floor((seconds / 3600) / workingHoursPerDay) > 1 ? "s" : ""}`;
        }
        hours = (Math.floor(seconds / 3600)).toString().padStart(2, "0");
        secondsLeft = seconds % 3600;
        minutes = (Math.floor(secondsLeft / 60)).toString().padStart(2, "0");
        secs = (Math.floor(secondsLeft % 60)).toString().padStart(2, "0");
        if (deadline) {
            return `${hours} : ${minutes} : ${secs}`;
        } else {
            return `${hours} : ${minutes} : ${secs}` === "00 : 00 : 00" ?
                "< - - - >" :
                `${hours} : ${minutes} : ${secs}`;
        }
    }

    function calculateDeadline(task: TaskModel) {
      let workingHoursPerDay: number;
      let allSecs: number = 0;
      switch (task.taskListType) {
        case TaskListSectionModel.CONFIRMED_COMPLETE: {
          setCriticalIn("-")
          break;
        }
        case TaskListSectionModel.DECLARED_COMPLETE: {
          setCriticalIn("-")
          break;
        }
        case TaskListSectionModel.WORK_IN_PROCESS: {
            const calendar: CalendarModel = activeProject?.calendars.find(
                (el: CalendarModel) => +el.calendar_id === +task.calendar_id
            );

            workingHoursPerDay = calendar ? calendar.working_hours_per_day ? calendar.working_hours_per_day : 8 : 8;
            allSecs = getSecsDuration(
                new Date(today),
                task.late_end_date.toDate(),
                calendar
            );
            setCriticalIn(formatTimeDifference(allSecs, workingHoursPerDay, true))
            break;
        }
          case TaskListSectionModel.QUEUED: {
              const calendar: CalendarModel = activeProject?.calendars.find(
                  (el: CalendarModel) => +el.calendar_id === +task.calendar_id
              );

              workingHoursPerDay = calendar ? calendar.working_hours_per_day ? calendar.working_hours_per_day : 8 : 8;
              allSecs = getSecsDuration(
                  new Date(),
                  task.late_end_date.toDate(),
                  calendar
              );
              setCriticalIn(formatTimeDifference(allSecs, workingHoursPerDay, true))
              break;
          }
      }
    }

    function setTarget(task: TaskModel) {
        let actualDuration: number, originalDuration: number, hours: string | number, minutes: string | number, seconds: string | number;
        let calendar: CalendarModel = activeProject?.calendars.find(
            (el: CalendarModel) => +el.calendar_id === +task.calendar_id
        );
        const workingHoursPerDay = calendar ? calendar.working_hours_per_day ? calendar.working_hours_per_day : 8 : 8;
        switch (task.status) {
            case TaskStatusModel.COMPLETE: {
                originalDuration = task.targetDuration;
                actualDuration = getSecsDuration(task.act_start_date!.toDate(), task.act_end_date!.toDate(), calendar) / 1800;
                setTaskInfo(formatTimeDifference((originalDuration - actualDuration) * 1800,
                    calendar ? calendar.working_hours_per_day ? calendar.working_hours_per_day : 8 : 8,
                    false));
                if (actualDuration > originalDuration) {
                    setTaskInfoClass("negative");
                }
                break;
            }
            case TaskStatusModel.DECLARED_COMPLETE: {
                originalDuration = task.targetDuration;

                actualDuration = getSecsDuration(task.act_start_date!.toDate(), task.declaredCompleteTimestamp!.toDate(), calendar) / 1800;
                setTaskInfo(formatTimeDifference((originalDuration - actualDuration) * 1800,
                    workingHoursPerDay,
                    false));
                if (actualDuration > originalDuration) {
                    setTaskInfoClass("negative");
                }
                break;
            }
            case TaskStatusModel.IN_PROGRESS:
            case TaskStatusModel.SUSPENDED: {
                const calendar: CalendarModel = activeProject?.calendars.find(
                    (el: CalendarModel) => +el.calendar_id === +task.calendar_id
                );
                const halfHours = task.targetFinishDate ? getSecsDuration(
                    new Date(),
                    task.targetFinishDate.toDate(),
                    calendar) / 1800 :
                    task.remainingDuration;
                setTaskInfo(formatTimeDifference(halfHours * 1800, workingHoursPerDay, false));
                if (task.constraint_type !== "CS_ALAP") {
                    if (task.float < workingHoursPerDay * 2) {
                        if (task.late_start_date.seconds * 1000 < tomorrow.getTime()) {
                            setIsCritical(true);
                            setIsCriticalPath(false)
                            setIsNearCritical(false);
                        } else {
                            setIsCriticalPath(true)
                            setIsNearCritical(false);
                        }
                    } else if (task.float <= criticalThreshold * workingHoursPerDay * 2) {
                        if (task.late_start_date.seconds * 1000 < tomorrow.getTime()) {
                            setIsCritical(true);
                            setIsCriticalPath(false)
                            setIsNearCritical(false);
                        } else {
                            setIsCriticalPath(false);
                            setIsNearCritical(true);
                        }
                    }
                } else {
                    if (task.late_start_date.seconds - (today.getTime() / 1000) < 86400) {
                        if (task.late_start_date.seconds * 1000 < tomorrow.getTime()) {
                            setIsCritical(true);
                            setIsCriticalPath(false)
                            setIsNearCritical(false);
                        } else {
                            setIsCriticalPath(true);
                            setIsNearCritical(false);
                        }
                    } else if (task.late_start_date.seconds - (today.getTime() / 1000) < 86400 * criticalThreshold) {
                        setIsCritical(false);
                        setIsNearCritical(true);
                    }
                }
                break;
            }
            case TaskStatusModel.NOT_STARTED:
            case TaskStatusModel.BLOCK: {
                const calendar: CalendarModel = activeProject?.calendars.find(
                    (el: CalendarModel) => +el.calendar_id === +task.calendar_id
                );

                const halfHours = task.targetFinishDate ? getSecsDuration(
                    new Date(),
                    task.targetFinishDate.toDate(),
                    calendar) / 1800 :
                    task.remainingDuration;
                setTaskInfo(formatTimeDifference(halfHours * 1800, workingHoursPerDay, false));
                if (task.constraint_type !== "CS_ALAP") {
                    if (task.float < workingHoursPerDay * 2) {
                        if (task.late_start_date.seconds * 1000 <= tomorrow.getTime()) {
                            setIsCritical(true);
                            setIsCriticalPath(false)
                            setIsNearCritical(false);
                        } else {
                            setIsCriticalPath(true);
                            setIsNearCritical(false);
                        }
                    } else if (task.float <= criticalThreshold * workingHoursPerDay * 2) {
                        setIsCritical(false);
                        setIsNearCritical(true);
                    }
                } else {
                    if (task.float < workingHoursPerDay * 2)  {
                        if (task.late_start_date.seconds * 1000 <= tomorrow.getTime()) {
                            setIsCritical(true);
                            setIsCriticalPath(false)
                            setIsNearCritical(false);
                        } else {
                            setIsCriticalPath(true);
                            setIsNearCritical(false);
                        }
                    } else if (task.late_start_date.seconds - (today.getTime() / 1000) < 86400 * criticalThreshold) {
                        setIsCritical(false);
                        setIsNearCritical(true);
                    }
                }
                break;
            }
        }
        return calendar;
    }

    const taskProgress = useMemo(() => {
      if (
        task.status === TaskStatusModel.COMPLETE ||
        task.status === TaskStatusModel.DECLARED_COMPLETE
      ) {
        return 100;
      } else {
        if (task.checklist && task.checklist.length > 0) {
          const filteredChecklist = task.checklist.filter((el) => el.isChecked);
          const percentage =
            (filteredChecklist.length / task.checklist.length) * 100;
          return percentage;
        }
        return 0;
      }
    }, [task.status, task.checklist]);

    const getActualPeriodInProcess = useCallback(() => {
      return task.enteredWorkInProcessTime
        ? daysArrayFromDuration(
            task.enteredWorkInProcessTime.toDate(),
            new Date()
          ).length - 2
        : 0;
    }, [task.enteredWorkInProcessTime]);

    useEffect(
      () => {
          let interval
          const calendar = setTarget(task)
          calculateDeadline(task)
          if (task.status == TaskStatusModel.IN_PROGRESS) {
            interval = setInterval(function () {
              let isNonWorking = isNonWorkingPeriod(new Date(), calendar);
              let timeNow = new Date().getTime();
              let endDate = task.targetFinishDate ? task.targetFinishDate.toDate() : task.late_end_date.toDate();
              if (!isNonWorking) {
                setSpinningIcon(true);
                if (endDate.getTime() > timeNow) {
                  setTaskInfoClass(null);
                } else if (endDate.getTime() < timeNow) {
                  setTaskInfoClass("negative");
                }
              } else {
                setSpinningIcon(false);
                if (endDate.getTime() > timeNow) {
                  setTaskInfoClass(null);
                } else if (endDate.getTime() < timeNow) {
                  setTaskInfoClass("negative");
                }
              }
                calculateDeadline(task)
                setTarget(task)
            }, 1000);
            } else if (task.taskListType === TaskListSectionModel.WORK_IN_PROCESS) {
              setSpinningIcon(false);
          }
        return () => {
          clearInterval(interval);
        };
      },
      [task]
    );

    const formatCriticalInTimeDifference = (days) => {
      if (days <= 0) {
        return "0 days";
      } else if (days === 1) {
        return "1 day";
      } else if (days <= 5) {
        return `${days} days`;
      } else if (days <= 20) {
        const weeks = Math.floor(days / 5);
        return `${weeks} week${weeks > 1 ? "s" : ""}`;
      } else {
        const months = Math.floor(days / 20);
        return `${months} month${months > 1 ? "s" : ""}`;
      }
    };

    const checkUserSeen = (task: any, currentUser: any) => {
      const userInTask =
        task.users &&
        task.users.find((el: any) => el.userId === currentUser?.userId);
      return userInTask && !userInTask.seen;
    }

    const selectActiveTask = () => {
        if (activeTask && activeTask.task_id === task.task_id && !taskOptionsOpen && !editReason && !showEventModal) {
            dispatch(taskActions.Actions.setActiveTask(null));
        } else {
            if (openPredecessors && goToPred) {
                goToPred();
            }
            else if (openSuccessors && goToSuc) {
                goToSuc();
            }
            else {
                dispatch(taskActions.Actions.setActiveTask(task));
            }
        }
    }

    const lightSelectTask = useCallback(
      (task: Maybe<TaskModel>) => {
        clearTimeout(delayOnMouseEnter as any);
        setDelayOnMouseEnter(
          // @ts-ignore
          setTimeout(function () {
            // @ts-ignore
            dispatch(taskActions.Actions.lightSelectedTask(task));
          }, 500)
        );
      },
      [delayOnMouseEnter, dispatch]
    );

    const handleDelayReason = (reason: string) => {
        setModalMessage(EventTextModel.LOG_ISSUE)
        setSuspendReason(reason);
        setShowEventModal(true);
        return
        // return FirebaseUsage.updateDoc(COLLECTIONS.TASKS, task.task_id, {overdueReason: reason})
        //     .catch((e) => console.error(e));
    }

    const targetFronts = activeProject ? activeProject?.targetFronts[activeProject.calendarDict![task.calendar_id]] : 0

    return (
      <div
        className={classNames(styles.Container, {
          [styles.Active]: Boolean(
            activeTask && activeTask.task_id === task.task_id
          ),
          [styles.MorePadding]: successorsView || predecessorsView,
        })}
        onClick={selectActiveTask}
        onMouseEnter={() => lightSelectTask(task)}
        onMouseLeave={() => lightSelectTask(null)}
      >
        {(task.status !== TaskStatusModel.COMPLETE &&
          task.status !== TaskStatusModel.DECLARED_COMPLETE) && (
          <p className={styles.index}>{task.index}</p>
        )}
        {goToPred && !props.hidePassBtn && (
          <div
            className={classNames(styles.GoToRelationBtn, styles.GoToPred)}
            onClick={() => goToPred()}
            onMouseEnter={() => setTaskOptionsOpen(true)}
            onMouseLeave={() => setTaskOptionsOpen(false)}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </div>
        )}
        {checkUserSeen(task, currentUser) ? (
          <div className={styles.Taskforcemessage}>
            <FontAwesomeIcon icon={faHandshakeAngle} />

          </div>
        ) : null}
        {currentUser &&
          task.taskForce.some((el) => el === currentUser.userId) && (
            <div className={`${checkUserSeen(task, currentUser) ? styles.Taskforcemessage : styles.Taskforce}`}>
              <FontAwesomeIcon icon={faHandshakeAngle} />
            </div>
          )}
        {task.task_type !== TaskType.TT_TASK && task.task_type !== TaskType.TT_RSRC ? (
          <div className={styles.IconWrap}>
            <TaskOptions showPopupHeader={false}
                         task={task}
                         type={props.type}
                         setTaskOptionsOpen={setTaskOptionsOpen}
                         isModalOpened={showEventModal}
                         setIsModalOpened={setShowEventModal}
                         setEventText={setModalMessage}
                        setSuspendReason={setSuspendReason}
                        setEvaluationResult={setEvaluationResult}
                        setDefDate={setDefDate}
                         defDate={defDate}>
              <>
                {task.status === TaskStatusModel.IN_PROGRESS &&
                !spinningIcon ? (
                  <FontAwesomeIcon
                    className={styles.SnoozeIcon}
                    icon={faSnooze}
                  />
                ) : null}
                {loading ? (
                  <Loader active inline size="small" />
                ) : (
                  <TaskIcon
                    taskListType={task.taskListType}
                    task={task}
                    spinningIcon={spinningIcon}
                    targetFronts={targetFronts}
                  />
                )}
              </>
            </TaskOptions>
            <div className={styles.ProgressBarWrap}>
              <div className={styles.ProgressBar}>
                <div
                  style={task.taskListType === TaskListSectionModel.WORK_IN_PROCESS && task.checklist.length === 0 ? {
                      height: "100%"} : {
                    height: `${taskProgress}%`,
                  }}
                  className={task.status !== TaskStatusModel.DECLARED_COMPLETE && task.status !== TaskStatusModel.COMPLETE && task.checklist.length === 0 ?
                      task.predStatus === 1 ? styles.LineGreen : task.predStatus === 2 ? styles.LineYellow : styles.LineRed : styles.LineBlue}
                />
              </div>
            </div>
          </div>
        ) : (
          <TaskOptions showPopupHeader={false}
                       task={task}
                       type={props.type}
                       setTaskOptionsOpen={setTaskOptionsOpen}
                       isModalOpened={showEventModal}
                       setIsModalOpened={setShowEventModal}
                       setEventText={setModalMessage}
                       setSuspendReason={setSuspendReason}
                       setEvaluationResult={setEvaluationResult}
                       setDefDate={setDefDate}
                       defDate={defDate}>
            <>
              <div className={styles.IconWrap}>
                {task.taskListType === TaskListSectionModel.WORK_IN_PROCESS && task.status === TaskStatusModel.IN_PROGRESS &&
                !spinningIcon ? (
                  <FontAwesomeIcon
                    className={styles.SnoozeIcon}
                    icon={faSnooze}
                  />
                ) : null}
                {loading ? (
                  <Loader active inline size="small" />
                ) : (
                  <TaskIcon
                    taskListType={task.taskListType}
                    task={task}
                    spinningIcon={spinningIcon}
                    targetFronts={targetFronts}
                  />
                )}
                <div className={styles.ProgressBarWrap}>
                  <div className={styles.ProgressBar}>
                    <div
                        style={(task.status === TaskStatusModel.IN_PROGRESS || task.status === TaskStatusModel.SUSPENDED || task.flow)
                        && task.checklist.length === 0 ? {
                            height: "100%"} : {
                            height: `${taskProgress}%`,
                        }}
                        className={task.status !== TaskStatusModel.DECLARED_COMPLETE && task.status !== TaskStatusModel.COMPLETE && task.checklist.length === 0 ?
                            task.predStatus === 1 ? styles.LineGreen : task.predStatus === 2 ? styles.LineYellow : styles.LineRed : styles.LineBlue}
                    />
                  </div>
                </div>
              </div>
            </>
          </TaskOptions>
        )}
        <div className={styles.Description}>
          <div className="d-flex">
            <h4>{task.wbs}</h4>
            {task.suspendReason ? (
              <span className={styles.Reason}>{task.suspendReason}</span>
            ) : task.overdueReason ? (
                <span className={styles.Reason}>{task.overdueReason}</span>
                ) : null}
          </div>
          <h5>
            {`${task.task_name}`}
            <span className={styles.TaskCode}>{` (${task.task_code})`}{
                task.progress && task.status === TaskStatusModel.IN_PROGRESS &&
                ` - (${ sliderValue }%)`}
            </span>
          </h5>
            {activeTask && task.task_id === activeTask.task_id && task.status === TaskStatusModel.IN_PROGRESS && (
                <span style={{display: "grid", gridTemplateColumns: "95% 5%", marginTop: "5px"}} onMouseUp={() => {
                    if (progressChanged) {
                        TaskThunk.updateTaskProgress(activeTask!, sliderValue).catch((e) => console.error(e));
                        setProgressChanged(false);
                        if (sliderValue === 100) {
                            setModalMessage(EventTextModel.COMPLETE_TASK)
                            setShowEventModal(true);
                        }
                    }
                }} onMouseLeave={() => {
                    if (progressChanged) {
                        TaskThunk.updateTaskProgress(activeTask!, sliderValue).catch((e) => console.error(e));
                        setProgressChanged(false);
                        if (sliderValue === 100) {
                            setModalMessage(EventTextModel.COMPLETE_TASK)
                            setShowEventModal(true);
                        }
                    }
                }}
                >
            <Slider value={sliderValue} className={styles.ui} color="grey" settings={sliderSettings} disabled={!task.taskForce.includes(currentUser!.userId)}/>
                    <p>{sliderValue}%</p>
                </span>
            )}
        </div>
          {
                showEventModal &&
              (<TaskEventModal
                  eventText={modalMessage}
                  opened={showEventModal}
                  setOpened={setShowEventModal}
                  task={task}
                  suspendReason={suspendReason}
                  // evaluationResult={evaluationResult}
                  defaultDate={defDate}/>)
          }
        {props.showPredType && predType && (
          <div className={styles.PredTypeLabel}>{predType} - </div>
        )}
        <div className={styles.rightFilters}>
          <div className={styles.TaskInfo} style={{textAlign: "right"}}>
            <div className={taskInfoClass ? styles.Negative : ""}>
              {taskInfo}
                <br/>
                {taskInfoClass === "negative" && !task.overdueReason ?
                    <div className={taskInfoClass ? styles.Negative : ""}
                         style={{
                             display: "flex",
                             flexDirection: "column",
                             justifyContent: "center",
                             alignItems: "center",
                             height: "100%",
                             marginRight: "-20px",
                             fontSize: "12px",
                         }}
                    >
                        <Dropdown scrolling text={"Log issue"}
                                  onChange={(e, {value}) =>
                                      handleDelayReason(value as string)}
                                  options={activeProject!.issueCategories.map((el) => ({
                                      key: el.reason,
                                      text: el.text,
                                      value: el.reason,
                                  }))}
                        />
                    </div>
                    :
                    // <span onClick={() => setEditReason(true)} className={styles.Reason}>{task.overdueReason}</span> :
                    null
                }
            </div>
          </div>
          <div className={styles.criticalIn}>
            <p>{criticalIn}</p>
            {isCritical ? <p className={styles.critical}>Critical</p>
                : isCriticalPath ? <p className={styles.criticalPath}>Critical path</p> :  null}
            {isNearCritical && (
              <p className={styles.nearCritical}>Near-critical</p>
            )}
          </div>
        </div>
        {!props.hidePassBtn && goToSuc && (
          <div
            className={classNames(styles.GoToRelationBtn, styles.GoToSuc)}
            onClick={() => goToSuc()}
            onMouseEnter={() => setTaskOptionsOpen(true)}
            onMouseLeave={() => setTaskOptionsOpen(false)}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        )}
      </div>
    );
  }
);
