import FirebaseUsage from "../../../../firebase/firebase.usage";
import TaskModel, {TaskConstraintType} from "../../../../models/responses/task.model";
import ProjectModel from "../../../../models/responses/project.model";
import TaskStatusModel from "../../../../models/responses/task-status.model";
import {TaskListSectionModel} from "../../../../models/task-list-section.model";
import {COLLECTIONS} from "../../../../firebase/constants";
import {CpmTaskModel} from "../../../../models/responses/cpm-task.model";
import {MessageType} from "../../../../models/responses/message.model";

const masterCalendarDict = (seconds, calendarId, map) => {
    return parseInt(map.get(`mcd:${seconds}:${calendarId}`))
}

const specificCalendarDict = (index, calendarId, map) => {
    return parseInt(map.get(`scd:${calendarId}:${index}`))
}

const masterWorkPatternDict = (index, calendarId, map) => {
    return parseInt(map.get(`mwp:${index}:${calendarId}`))
}

export function convertIndexToSeconds(index, calendarId, map) {
    const date = specificCalendarDict(index, calendarId, map)
    if (isNaN(date)) {
        return convertOutOfRangeIndexToDate(index, calendarId, map)
    } else {
        return date
    }
}

function convertOutOfRangeDateToIndex(date, calendarId, map) {
    const maxDate = parseInt(map.get(`swp:maxDate:${calendarId}`))
    const maxIndex = parseInt(map.get(`swp:maxIndex:${calendarId}`))
    const halfHours = parseInt(map.get(`swp:wi:count:${calendarId}`))
    const weeks = Math.floor((date - maxDate) / 604800)
    const maxWeekIndex = parseInt(map.get(`swp:maxWeekIndex:${calendarId}`))
    const relativeMaxWeekIndex = masterWorkPatternDict(maxWeekIndex, calendarId, map)
    const remainingHalfHours = Math.floor((date - maxDate) / 1800) - (weeks * 336)
    const newMasterIndex = remainingHalfHours + maxWeekIndex > 336 ? (remainingHalfHours + maxWeekIndex) - 336 : remainingHalfHours + maxWeekIndex
    const newCalendarSpecificIndex = masterWorkPatternDict(newMasterIndex, calendarId, map)

    const newIndex = remainingHalfHours + maxWeekIndex > 336 ? maxIndex + (weeks * halfHours) + (halfHours - relativeMaxWeekIndex) + (newCalendarSpecificIndex) :
        maxIndex + (weeks * halfHours) + (newCalendarSpecificIndex - relativeMaxWeekIndex)

    // console.log("maxDate: ", maxDate, "maxIndex: ", maxIndex, "halfHours: ", halfHours, "weeks: ", weeks, "maxWeekIndex: ", maxWeekIndex, "remainingHalfHours: ", remainingHalfHours, "newMasterIndex: ", newMasterIndex, "newCalendarSpecificIndex: ", newCalendarSpecificIndex, "newIndex: ", newIndex)

    return newIndex
}

export function convertDateToIndex(date, calendarId, map) {
    let index = masterCalendarDict(date, calendarId, map)
    if (isNaN(index)) {
        return convertOutOfRangeDateToIndex(date, calendarId, map)
    }
    return index
}

function convertOutOfRangeIndexToDate(index, calendarId, map) {
    const maxDate = parseInt(map.get(`swp:maxDate:${calendarId}`))
    const maxIndex = parseInt(map.get(`swp:maxIndex:${calendarId}`))
    // const weeklyIndices = specificWorkPatternDict[calendarId].weeklyIndices
    const halfHours = parseInt(map.get(`swp:wi:count:${calendarId}`))
    const weeks = Math.floor((index - maxIndex) / halfHours)
    const maxWeekIndex = parseInt(map.get(`swp:maxWeekIndex:${calendarId}`))
    const relativeMaxWeekIndex = masterWorkPatternDict(maxWeekIndex, calendarId, map)
    const relativeMaxDayIndex = parseInt(map.get(`swp:maxDayIndex:${calendarId}`))
    const relativeMaxHalfHourIndex = parseInt(map.get(`swp:wi:h:${calendarId}:${relativeMaxWeekIndex}`))
    const remainingHalfHours = index - maxIndex - (weeks * halfHours)
    const newIndex = remainingHalfHours + relativeMaxWeekIndex > halfHours ? (remainingHalfHours + relativeMaxWeekIndex) - halfHours : remainingHalfHours + relativeMaxWeekIndex
    const newWeekDayIndex = parseInt(map.get(`swp:wi:d:${calendarId}:${newIndex}`))
    const additionalDays = relativeMaxWeekIndex + remainingHalfHours > halfHours ? 7 - relativeMaxDayIndex + newWeekDayIndex : newWeekDayIndex - relativeMaxDayIndex

    return maxDate + (weeks * 604800) + (additionalDays * 86400) + ((parseInt(map.get(`swp:wi:h:${calendarId}:${newIndex}`)) - relativeMaxHalfHourIndex) * 1800)
}


export default async function handleEvent(event,
                                          taskId,
                                          secondStr,
                                          db,
                                          userId,
                                          projectId,
                                          userEmail,
                                          expiryDate,
                                          suspendReason,
                                          tasksMap,
                                          calendarsMap,
                                          reasonText,
) {


    const seconds = parseInt(secondStr)
    const secondsToNearestHalfHour = Math.round(seconds / 1800) * 1800
    const startDateSecondsToNearestHalfHour = Math.round(parseInt(expiryDate) / 1800) * 1800
    const taskDoc = FirebaseUsage.getDocumentRef(COLLECTIONS.TASKS, taskId)
    const ledgerEntry = FirebaseUsage.getBlankDoc(COLLECTIONS.LEDGER_ENTRY)
    let taskData: TaskModel = await FirebaseUsage.getDocWithRef(taskDoc).then(doc => doc.data() as TaskModel)
    const projectDoc= FirebaseUsage.getDocumentRef(COLLECTIONS.PROJECTS, projectId)
    const projectData: ProjectModel = await FirebaseUsage.getDocWithRef(projectDoc).then(doc => doc.data() as ProjectModel)
    let task: CpmTaskModel | any = tasksMap.get(taskId)
    const dataDate = convertDateToIndex(Math.floor((new Date().getTime() / 1000) / 86400) * 86400, task.cal_id, calendarsMap)
    let finish: number
    const users = taskData.taskForce.length > 0 ?
        taskData.taskForce
            .map((user) => ({userId: user, seen: user === userId}))
        : []

    switch (event) {
        case 'start':
            console.log("Starting task: ", taskId)
            let start = convertDateToIndex(secondsToNearestHalfHour, task.cal_id, calendarsMap)
            finish = task.duration > 0 ? start + task.duration - 1 : start
            let duration = Math.max(0, finish - dataDate)
            let newExpiryDate = convertIndexToSeconds(finish, task.cal_id, calendarsMap)
            tasksMap.set(taskId, {...task,
                status_code: TaskStatusModel.IN_PROGRESS,
                es: start,
                ef: Math.max(dataDate, finish),
                ad: dataDate,
                duration: duration
            })
            console.log({...task,
                status_code: TaskStatusModel.IN_PROGRESS,
                es: start,
                ef: Math.max(dataDate, finish),
                ad: dataDate,
                duration: duration
            })
            await FirebaseUsage.updateDoc('tasks',taskId,{
                act_start_date: FirebaseUsage.timestamp(new Date(secondsToNearestHalfHour * 1000)),
                enteredWorkInProcessTime: FirebaseUsage.timestamp(new Date(seconds * 1000)),
                expiryDate: FirebaseUsage.timestamp(new Date(newExpiryDate * 1000)),
                targetFinishDate: FirebaseUsage.timestamp(new Date(newExpiryDate * 1000)),
                early_end_date: FirebaseUsage.timestamp(new Date(newExpiryDate * 1000)),
                early_start_date: FirebaseUsage.timestamp(new Date(secondsToNearestHalfHour * 1000)),
                taskListType: TaskListSectionModel.WORK_IN_PROCESS,
                status: TaskStatusModel.IN_PROGRESS,
                processedFrom: taskData.taskListType,
                users: users
            }).then(() => console.log("update successful"))
            await FirebaseUsage.setDocumentWithDoc(ledgerEntry, {
                taskId: taskId,
                userId: userId,
                timestamp: FirebaseUsage.timestamp(new Date(seconds * 1000)),
                logTimestamp: FirebaseUsage.timestamp(new Date()),
                type: 'STD',
                ledgerId: ledgerEntry.id,
                projectId: projectId,
                userEmail: userEmail,
                predStatus: taskData.predStatus,
                priority: taskData.index ? taskData.index : null,
                flow: taskData.flow
            })
            break;

        case 'unstart':
            console.log("Unstarting task: ", taskId)
            tasksMap.set(taskId, {...task,
                status_code: TaskStatusModel.NOT_STARTED,
                es: task.ad,
                ef: task.ad + taskData.targetDuration,
                duration: taskData.targetDuration
            })
            await FirebaseUsage.updateDoc('tasks', taskId, {
                act_start_date: null,
                enteredWorkInProcessTime: null,
                expiryDate: null,
                targetFinishDate: null,
                early_end_date: FirebaseUsage.timestamp(new Date(convertIndexToSeconds(task.ad + taskData.targetDuration, task.cal_id, calendarsMap) * 1000)),
                early_start_date: FirebaseUsage.timestamp(new Date(convertIndexToSeconds(task.ad, task.cal_id, calendarsMap) * 1000)),
                remainingDuration: taskData.targetDuration,
                status: TaskStatusModel.NOT_STARTED,
                users: users
            })
            await FirebaseUsage.setDocumentWithDoc(ledgerEntry, {
                taskId: taskId,
                userId: userId,
                timestamp: FirebaseUsage.timestamp(new Date(seconds * 1000)),
                logTimestamp: FirebaseUsage.timestamp(new Date()),
                type: 'UST',
                ledgerId: ledgerEntry.id,
                projectId: projectId,
                userEmail: userEmail,
                predStatus: taskData.predStatus,
                priority: taskData.index ? taskData.index : null,
                flow: taskData.flow
            })
            break

        case 'confirmStart':
            console.log("Confirming start of task: ", taskId)
            start = convertDateToIndex(secondsToNearestHalfHour, task.cal_id, calendarsMap)
            newExpiryDate = convertIndexToSeconds(start + taskData.targetDuration, task.cal_id, calendarsMap)
            await FirebaseUsage.updateDoc('tasks', taskId, {
                act_start_date: FirebaseUsage.timestamp(new Date(seconds * 1000)),
                targetFinishDate: FirebaseUsage.timestamp(new Date(newExpiryDate * 1000)),
            })
            await FirebaseUsage.setDocumentWithDoc(ledgerEntry, {
                taskId: taskId,
                userId: userId,
                timestamp: FirebaseUsage.timestamp(new Date(seconds * 1000)),
                logTimestamp: FirebaseUsage.timestamp(new Date()),
                type: 'COS',
                ledgerId: ledgerEntry.id,
                projectId: projectId,
                userEmail: userEmail,
                predStatus: taskData.predStatus,
                priority: taskData.index ? taskData.index : null,
                flow: taskData.flow
            })
            break

        case 'finish':
            tasksMap.set(taskId, {...task,
                status_code: TaskStatusModel.COMPLETE,
                ef: convertDateToIndex(secondsToNearestHalfHour - 1800, task.cal_id, calendarsMap),
                ad: dataDate,
                duration: 0
            })
            await FirebaseUsage.updateDoc('tasks', taskId, {
                declaredCompleteTimestamp: FirebaseUsage.timestamp(new Date(seconds * 1000)),
                declaredCompleteBy: userId,
                taskListType: TaskListSectionModel.DECLARED_COMPLETE,
                status: TaskStatusModel.DECLARED_COMPLETE,
                processedFrom: taskData.taskListType,
                users: users
            })
            await FirebaseUsage.setDocumentWithDoc(ledgerEntry, {
                taskId: taskId,
                userId: userId,
                timestamp: FirebaseUsage.timestamp(new Date(seconds * 1000)),
                logTimestamp: FirebaseUsage.timestamp(new Date()),
                type: 'DEC',
                ledgerId: ledgerEntry.id,
                projectId: projectId,
                userEmail: userEmail,
                predStatus: taskData.predStatus,
                priority: taskData.index ? taskData.index : null,
                flow: taskData.flow
            })
            break;

        case 'confirm':
            const startDateIndex = convertDateToIndex(startDateSecondsToNearestHalfHour, task.cal_id, calendarsMap)
            console.log("Confirming task: ", taskId)
            console.log(startDateSecondsToNearestHalfHour)
            tasksMap.set(taskId, {...task,
                status_code: TaskStatusModel.COMPLETE,
                es: startDateIndex,
                ef: convertDateToIndex(secondsToNearestHalfHour - 1800, task.cal_id, calendarsMap),
                duration: 0
            })
            await FirebaseUsage.updateDoc('tasks', taskId, {
                confirmedCompleteTimestamp: FirebaseUsage.timestamp(new Date(seconds * 1000)),
                act_start_date: FirebaseUsage.timestamp(new Date(startDateSecondsToNearestHalfHour * 1000)),
                act_end_date: FirebaseUsage.timestamp(new Date(seconds * 1000)),
                processedFrom: taskData.taskListType,
                taskListType: TaskListSectionModel.CONFIRMED_COMPLETE,
                status: TaskStatusModel.COMPLETE,
                users: users
            })
            await FirebaseUsage.setDocumentWithDoc(ledgerEntry, {
                taskId: taskId,
                userId: userId,
                timestamp: FirebaseUsage.timestamp(new Date(seconds * 1000)),
                logTimestamp: FirebaseUsage.timestamp(new Date()),
                type: 'COM',
                ledgerId: ledgerEntry.id,
                projectId: projectId,
                userEmail: userEmail,
                priority: taskData.index ? taskData.index : null,
                flow: taskData.flow
            })
            break;

        case 'duration':
            console.log("Updating duration for task: ", taskId)
            const endDate = FirebaseUsage.timestamp(new Date(seconds * 1000))
            const remainingDuration = Math.ceil(parseInt(expiryDate) / 1800)
            finish = convertDateToIndex(secondsToNearestHalfHour - 1800, task.cal_id, calendarsMap)
            tasksMap.set(taskId, {...task,
                duration: Math.max(0, task.status_code !== TaskStatusModel.NOT_STARTED ? finish - dataDate : remainingDuration),
                ef: finish,
                lf: finish > task.lf ? finish : task.lf,
            })
            await FirebaseUsage.updateDoc('tasks', taskId, {
                early_end_date: endDate,
                remainingDuration: remainingDuration,
                expiryDate: endDate,
                late_end_date: endDate > taskData.late_end_date ? endDate : taskData.late_end_date,
                users: users
            })
            await FirebaseUsage.setDocumentWithDoc(ledgerEntry, {
                taskId: taskId,
                userId: userId,
                timestamp: FirebaseUsage.timestamp(new Date(seconds * 1000)),
                logTimestamp: FirebaseUsage.timestamp(new Date()),
                type: 'FOR',
                ledgerId: ledgerEntry.id,
                projectId: projectId,
                userEmail: userEmail,
                predStatus: taskData.predStatus,
                priority: taskData.index ? taskData.index : null,
                flow: taskData.flow
            })
            break;
        case 'reject':
            console.log("Unfinishing task: ", taskId)
            tasksMap.set(taskId, {...task,
                status_code: TaskStatusModel.IN_PROGRESS,
                ef: task.ad,
                duration: 0
            })
            await FirebaseUsage.updateDoc('tasks', taskId, {
                declaredCompleteTimestamp: null,
                declaredCompleteBy: null,
                taskListType: TaskListSectionModel.WORK_IN_PROCESS,
                status: TaskStatusModel.IN_PROGRESS,
                processedFrom: taskData.taskListType,
                users: users,
                expiryDate: taskData.expiryDate ? taskData.expiryDate : taskData.early_end_date
            })
            await FirebaseUsage.setDocumentWithDoc(ledgerEntry, {
                taskId: taskId,
                userId: userId,
                timestamp: FirebaseUsage.timestamp(new Date(seconds * 1000)),
                logTimestamp: FirebaseUsage.timestamp(new Date()),
                type: 'REJ',
                ledgerId: ledgerEntry.id,
                projectId: projectId,
                userEmail: userEmail,
                priority: taskData.index ? taskData.index : null,
                flow: taskData.flow
            })
            break;
        case 'block':
            console.log("Blocking task: ", taskId)
            let blockedTasks = projectData.blocked
            blockedTasks[taskId] = true
            taskData = {...taskData,
                suspended: true,
                suspendReason: suspendReason,
                status: TaskStatusModel.BLOCK,
                blocked: true,
                processedFrom: taskData.taskListType,
                users: users
            }
            await FirebaseUsage.updateDoc('tasks', taskId, taskData)
            await FirebaseUsage.updateDoc('projects', projectId, {blocked: blockedTasks})
            await FirebaseUsage.setDocumentWithDoc(ledgerEntry, {
                taskId: taskId,
                userId: userId,
                timestamp: FirebaseUsage.timestamp(new Date(seconds * 1000)),
                logTimestamp: FirebaseUsage.timestamp(new Date()),
                type: 'BLC',
                ledgerId: ledgerEntry.id,
                projectId: projectId,
                userEmail: userEmail,
                suspendReason: suspendReason,
                predStatus: taskData.predStatus,
                priority: taskData.index ? taskData.index : null,
                flow: taskData.flow,
                text: reasonText
            })
            break;
        case 'unblock':
            console.log("Unblocking task: ", taskId)
            let unblockedTasks = projectData.blocked
            delete unblockedTasks[taskId]
            taskData = {...taskData,
                suspended: false,
                suspendReason: "",
                status: TaskStatusModel.NOT_STARTED,
                blocked: false,
                taskListType: taskData.processedFrom,
                users: users
            }
            await FirebaseUsage.updateDoc('tasks', taskId, taskData)
            await FirebaseUsage.updateDoc('projects', projectId, {blocked: unblockedTasks})
            await FirebaseUsage.setDocumentWithDoc(ledgerEntry, {
                taskId: taskId,
                userId: userId,
                timestamp: FirebaseUsage.timestamp(new Date(seconds * 1000)),
                logTimestamp: FirebaseUsage.timestamp(new Date()),
                type: MessageType.UNB,
                ledgerId: ledgerEntry.id,
                projectId: projectId,
                userEmail: userEmail,
                priority: taskData.index ? taskData.index : null,
                flow: taskData.flow
            })
            break;
        case 'suspend':
            console.log("Suspending task: ", taskId)
            let suspendedTasks = projectData.suspended
            suspendedTasks[taskId] = true
            tasksMap.set(taskId, {...task,
                status_code: TaskStatusModel.SUSPENDED
            })
            taskData = {...taskData,
                suspended: true,
                suspendReason: suspendReason,
                status: TaskStatusModel.SUSPENDED,
                users: users
            }
            await FirebaseUsage.updateDoc('tasks', taskId, taskData)
            await FirebaseUsage.updateDoc('projects', projectId, {suspended: suspendedTasks})
            await FirebaseUsage.setDocumentWithDoc(ledgerEntry, {
                taskId: taskId,
                userId: userId,
                timestamp: FirebaseUsage.timestamp(new Date(seconds * 1000)),
                logTimestamp: FirebaseUsage.timestamp(new Date()),
                type: MessageType.SUS,
                ledgerId: ledgerEntry.id,
                projectId: projectId,
                userEmail: userEmail,
                suspendReason: suspendReason,
                predStatus: taskData.predStatus,
                priority: taskData.index ? taskData.index : null,
                flow: taskData.flow,
                text: reasonText
            })
            break;
        case 'resume':
            console.log("Resuming task: ", taskId)
            let resumedTasks = projectData.suspended
            delete resumedTasks[taskId]
            tasksMap.set(taskId, {...task,
                status_code: TaskStatusModel.IN_PROGRESS
            })
            taskData = {...taskData,
                suspended: false,
                suspendReason: "",
                status: TaskStatusModel.IN_PROGRESS,
                taskListType: TaskListSectionModel.WORK_IN_PROCESS,
                users: users
            }
            await FirebaseUsage.updateDoc('tasks', taskId, taskData)
            await FirebaseUsage.updateDoc('projects', projectId, {suspended: resumedTasks})
            await FirebaseUsage.setDocumentWithDoc(ledgerEntry, {
                taskId: taskId,
                userId: userId,
                timestamp: FirebaseUsage.timestamp(new Date(seconds * 1000)),
                logTimestamp: FirebaseUsage.timestamp(new Date()),
                type: MessageType.RST,
                ledgerId: ledgerEntry.id,
                projectId: projectId,
                userEmail: userEmail,
                priority: taskData.index ? taskData.index : null,
                flow: taskData.flow
            })
            break;
        case 'constrain':
            console.log("Constraining task: ", taskId)
            const constraintDate = convertDateToIndex(secondsToNearestHalfHour, task.cal_id, calendarsMap)
            if (taskData.constraint_type === '') {
                tasksMap.set(taskId, {
                    ...task,
                    ad: Math.max(task.ad, constraintDate),
                    es: task.status === TaskStatusModel.NOT_STARTED || task.status === TaskStatusModel.BLOCK ? Math.max(task.es, constraintDate) : task.es,
                    ef: Math.max(constraintDate + task.duration - 1, task.es + task.duration - 1),
                    cstr_type: TaskConstraintType.START_ON_OR_AFTER
                })
            } else {
                tasksMap.set(taskId, {
                    ...task,
                    ad: constraintDate,
                    es: task.status === TaskStatusModel.NOT_STARTED || task.status === TaskStatusModel.BLOCK ? constraintDate : task.es,
                    ef: constraintDate + task.duration - 1,
                    cstr_type: TaskConstraintType.START_ON_OR_AFTER
                })
            }
            taskData = {...taskData,
                constraint_date: FirebaseUsage.timestamp(new Date(secondsToNearestHalfHour * 1000)),
                users: users,
                constraint_type: TaskConstraintType.START_ON_OR_AFTER,
                early_start_date: FirebaseUsage.timestamp(new Date(tasksMap.get(taskId).es * 1000)),
                early_end_date: FirebaseUsage.timestamp(new Date(tasksMap.get(taskId).ef * 1000)),
            }
            await FirebaseUsage.updateDoc('tasks', taskId, taskData)
            await FirebaseUsage.setDocumentWithDoc(ledgerEntry, {
                taskId: taskId,
                userId: userId,
                timestamp: FirebaseUsage.timestamp(new Date(seconds * 1000)),
                logTimestamp: FirebaseUsage.timestamp(new Date()),
                type: MessageType.CST,
                ledgerId: ledgerEntry.id,
                projectId: projectId,
                userEmail: userEmail,
                predStatus: taskData.predStatus,
                priority: taskData.index ? taskData.index : null,
                flow: taskData.flow
            })
            break;
        case 'issue':
            await FirebaseUsage.updateDoc('tasks', taskId, {overdueReason: suspendReason})
            await FirebaseUsage.setDocumentWithDoc(ledgerEntry, {
                taskId: taskId,
                userId: userId,
                timestamp: FirebaseUsage.timestamp(new Date(seconds * 1000)),
                logTimestamp: FirebaseUsage.timestamp(new Date()),
                type: MessageType.ISS,
                ledgerId: ledgerEntry.id,
                projectId: projectId,
                userEmail: userEmail,
                predStatus: taskData.predStatus,
                priority: taskData.index ? taskData.index : null,
                suspendReason,
                flow: taskData.flow,
                text: reasonText
            })
            break;
        case 'rename':
            await FirebaseUsage.setDocumentWithDoc(ledgerEntry, {
                taskId: taskId,
                userId: userId,
                timestamp: FirebaseUsage.timestamp(new Date(seconds * 1000)),
                logTimestamp: FirebaseUsage.timestamp(new Date()),
                type: MessageType.RNM,
                ledgerId: ledgerEntry.id,
                projectId: projectId,
                userEmail: userEmail,
                predStatus: taskData.predStatus,
                priority: taskData.index ? taskData.index : null,
                flow: taskData.flow,
                text: suspendReason,
                oldName: taskData.task_name
            })
            taskData = {...taskData,
                task_name: suspendReason,
                users: users
            }
            await FirebaseUsage.updateDoc('tasks', taskId, taskData)
            break;
        case 'approveNew':
            tasksMap.set(taskId, {...task,
                pending: false
            })
            await FirebaseUsage.updateDoc('tasks', taskId, {taskListType: TaskListSectionModel.QUEUED})
            await FirebaseUsage.setDocumentWithDoc(ledgerEntry, {
                taskId: taskId,
                userId: userId,
                timestamp: FirebaseUsage.timestamp(new Date(seconds * 1000)),
                logTimestamp: FirebaseUsage.timestamp(new Date()),
                type: MessageType.APN,
                ledgerId: ledgerEntry.id,
                projectId: projectId,
                userEmail: userEmail,
                predStatus: taskData.predStatus,
                priority: taskData.index ? taskData.index : null,
                flow: taskData.flow
            })
            break;
    }
    return tasksMap
}