import React, { useRef, useCallback, useEffect, useState } from 'react';
import {
  matchPath, Outlet, useNavigate
} from 'react-router-dom';
import HeaderContainer from './components/HeaderContainer/HeaderContainer';
import NavigationBarContainer from './components/NavigationBarContainer/NavigationBarContainer';
import {
  ProjectContentUI,
  ProjectMainContainerUI,
  ProjectWrapUI,
} from './ui/ProjectPageUI';
import RouteNameConstants from '../../constants/route-name.constants';
import {
  useActiveProjectSelector, useCalendarOpenSelector, useCpmCompleteSelector,
  useIsLoadedActiveProjectSelector, useLedgerOpenSelector, useTeamOpenSelector,
} from '../../store/selectors/project.selectors';
import { SearchBar } from './components/SearchBar/SearchBar';
import { HeaderLedger } from './components/HeaderContainer/components/HeaderLedger';
import { HeaderMember } from './components/HeaderContainer/components/HeaderMember';
import { useDispatch } from 'react-redux';
import AuthorizationThunk from '../../store/thunk/authorization.thunk';
import { useTypedSelector } from '../../store/selectors/selectors.utils';
import classNames from 'classnames';
import HeaderCalendar from './components/HeaderContainer/components/HeaderCalendar/HeaderCalendar';
import { subsManager } from '../../store/middleware/subs-manager/subs-manager';
import CustomerRequestModal from "./components/CustomerRequest/CustomerRequestModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMessageLines} from "@fortawesome/pro-regular-svg-icons";
import {Chat} from "./components/FlowChat/FlowChat";
import * as ledgerActions from "../../store/actions/ledger.actions";
import * as projectActions from "../../store/actions/project.actions";


const ProjectPage = () => {
  const activeProject = useActiveProjectSelector();
  const cpmComplete = useCpmCompleteSelector();
  const isLoadedActiveProject = useIsLoadedActiveProjectSelector();
  const isOpenedLedger = useLedgerOpenSelector();
  const isOpenedCalendar = useCalendarOpenSelector();
  const isOpenedMember = useTeamOpenSelector()
  const [isOpenedProfile, setProfileIsOpen] = useState(false);
  const [isLarge, setLargeMode] = useState<boolean>(false);
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [chatOpen, setChatOpen] = useState(false);
  const history = useNavigate();
  const openPredecessors = useTypedSelector(
    (state) => state.task.predecessors.open
  );
  const openSuccessors = useTypedSelector(
    (state) => state.task.successors.open
  );
  const activeTask = useTypedSelector((state) => state.task.activeTask);
  const dispatch = useDispatch();
  const isMountedRef = useRef(false);

  const openSpecificAdditional = useCallback(
    (type: 'ledger' | 'member' | 'profile' | 'calendar', open: boolean) => {
      if (type === 'ledger') {
        dispatch(projectActions.Actions.setLedgerOpen(open));
      } else if (type === 'member') {
        dispatch(projectActions.Actions.setTeamOpen(open));
      } else if (type === 'calendar') {
        dispatch(projectActions.Actions.setCalendarOpen(open));
      } else {
        setProfileIsOpen(prevState => !prevState);
      }
    },
    []
  );

  useEffect(() => {
    if (isOpenedLedger && activeTask) {
        subsManager.subscribeLedgerList(activeTask.task_id);
      } else {
        dispatch(ledgerActions.Actions.setLedgerList([]));
        subsManager.removeSubscription('ledger-list');
    }
  }, [isOpenedLedger, activeTask]);

  //
  const logOut = useCallback(
    (evt: any) => {
      evt.preventDefault();
      if (isMountedRef.current) {
        // @ts-ignore
        dispatch(AuthorizationThunk.logOut());
      }
    },
    [dispatch, isMountedRef]
  );

  useEffect(() => {
    isMountedRef.current = true;

    const matchPathname = matchPath( {path: '/:projectId/flow'}, window.location.pathname);
    const params: { projectId?: string } = matchPathname
      ? matchPathname.params
      : {};
    const activeProjectId =
      localStorage.getItem('projectId') || params.projectId;

    if (activeProject && isLoadedActiveProject && !activeProject.disabled) {
      // ProjectThunk.setActiveProjectId(activeProject.projectId);
      history(`/${activeProjectId}/${RouteNameConstants.FLOW}`);
    } else if (!activeProject && !activeProjectId) {
        history(`/${RouteNameConstants.CHOOSE_PROJECT}`);
    }

    if (!activeProject) {
      dispatch(projectActions.Actions.setLedgerOpen(false));
      dispatch(projectActions.Actions.setTeamOpen(false));
      dispatch(projectActions.Actions.setCalendarOpen(false));
    }
    return () => {
      isMountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProject, isLoadedActiveProject]);

  return (
    <ProjectMainContainerUI
      style={{ overflow: 'hidden', position: 'relative' }}
    >
      {/*<DebugModal />*/}
      <HeaderContainer
        logout={logOut}
        openSpecificAdditional={openSpecificAdditional}
        isOpenedCalendar={isOpenedCalendar}
        isOpenedLedger={isOpenedLedger}
        isOpenedMember={isOpenedMember}
        isOpenedProfile={isOpenedProfile}
        isLarge={isLarge}
        setLargeMode={setLargeMode}
        setRequestModalOpen={setRequestModalOpen}
      />
      <SearchBar disabled={openPredecessors || openSuccessors}
        openSpecificAdditional={openSpecificAdditional}
        isOpenedCalendar={isOpenedCalendar}
        isOpenedLedger={isOpenedLedger}
        isOpenedMember={isOpenedMember}
        isOpenedProfile={isOpenedProfile}
        isLarge={isLarge}
        setLargeMode={setLargeMode}
      />
      {requestModalOpen && <CustomerRequestModal onClose={() => setRequestModalOpen(false)} />}
      <ProjectWrapUI style={{ position: 'initial' }}>
        <NavigationBarContainer setLargeMode={setLargeMode} isLarge={isLarge} />
        <ProjectContentUI
          className="project-content"
        >
          <Outlet/>
        </ProjectContentUI>
        <div style={{ display: 'flex'}}>
          <div
            className={classNames('header-additional-content-wrap', {
              open: isLarge ? !isLarge : isOpenedCalendar,
            })}
          >
            <div className="header-additional-content">
              <HeaderCalendar />
            </div>
          </div>
          <div
            className={classNames('header-additional-content-wrap', {
              open: isLarge ? !isLarge : isOpenedLedger,
            })}
          >
            <div className="header-additional-content">
              <HeaderLedger />
            </div>
          </div>
          <div
            className={classNames('header-additional-content-wrap', {
              open: isLarge ? !isLarge : isOpenedMember,
            })}
          >
            <div className="header-additional-content">
              <HeaderMember />
            </div>
          </div>
        </div>
      </ProjectWrapUI>
      {cpmComplete && activeProject && !chatOpen &&
        <div className="open-chat" onClick={() => setChatOpen(true)}>
          <FontAwesomeIcon icon={faMessageLines} />
        </div>
      }
      {cpmComplete && activeProject && chatOpen &&
        <div className="chat-container">
          <Chat setShowChat={setChatOpen} />
        </div>
      }
    </ProjectMainContainerUI>
  )
};

export default ProjectPage;
